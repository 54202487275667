import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { legacyBillingStoreConnect } from 'src/state/store';
import { MakoStateType } from 'src/state/types/makoStateType';
import * as ProductActions from 'src/state/middle_tier/products/actions';
import * as UserProductActions from 'src/state/middle_tier/user_products/actions';
import { Props } from './TrialNotifications';

const getLoading = (loading: MakoStateType['loading']) => {
  return Object.values(loading).some((isLoading) => isLoading);
};

export const mapStateToProps = (state: MakoStateType) => {
  return {
    loading: getLoading(state.loading),
    emailApiSubscription: state.emailApiSubscription,
    marketingCampaignsSubscription: state.marketingCampaignsSubscription,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getProducts: () => {
      dispatch(ProductActions.Actions.getProducts());
    },
    getUserProducts: () => {
      dispatch(UserProductActions.Actions.getUserProducts());
    },
  };
};

export type TrialBannerConnectorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  Props;

export function withTrialBannerConnector(Component: React.FC<Props>) {
  const TrialBannerConnector: React.FC<TrialBannerConnectorProps> = (props) => {
    useEffect(() => {
      props.getProducts();
      props.getUserProducts();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
  };

  return legacyBillingStoreConnect(
    mapStateToProps,
    mapDispatchToProps
  )(TrialBannerConnector);
}
