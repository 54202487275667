import { Subscription } from 'src/state/types/subscription';
import {
  EI_END_OF_TRIAL_PREFIX,
  MC_END_OF_TRIAL_PREFIX,
  EI_TRIAL_PREFIX,
  MC_TRIAL_PREFIX,
} from 'src/helpers/offerings/trials';
import moment from 'moment';

export const getEndOfTrialDate = (
  subscription: Subscription
): string | null => {
  const isOnEiTrial = subscription.planId.startsWith(EI_TRIAL_PREFIX);
  const isOnMcTrial = subscription.planId.startsWith(MC_TRIAL_PREFIX);
  const isOnAnyTrial = isOnEiTrial || isOnMcTrial;
  // Make sure they're on a trial currently
  if (!isOnAnyTrial) {
    return null;
  }

  const downgradePlanId = subscription.downgradePlanId;
  // Trial isn't ending or has already ended
  if (!downgradePlanId) {
    return null;
  }

  const isMovingToEndOfTrial =
    downgradePlanId.startsWith(EI_END_OF_TRIAL_PREFIX) ||
    downgradePlanId.startsWith(MC_END_OF_TRIAL_PREFIX);
  if (!isMovingToEndOfTrial) {
    return null;
  }

  return subscription.downgradePlanStartDate ?? null;
};

export const formatTrialEndDate = (endOfTrialDate: string) => {
  return moment(endOfTrialDate).format('MMMM Do');
};
