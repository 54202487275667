import parseURI from 'parse-uri';

export const redirectToDashboard = () => {
  window.EventBus.trigger('navigate', '', () => {
    // We need to reload the page for Tiara to show up
    window.location.reload();
  });
};

export const getPublicPath = () => {
  return process.env.publicPath ?? '/';
};

export const isFeatureBranch = () => {
  return process.env.publicPath && process.env.publicPath !== '/';
};

export const redirectToUrl = (url: string) => {
  window.parent.location.href = url;
};

// This is intended to be passed into hrefs for a tags and button components
// i.e. should pass in route like logout to form /logout or /feature-branch/logout
// This especially helps if we need certain route links to work in both our feature branch env and staging/localhost env for our Cypress tests to work
// <a href="/logout"> would break in a feature branch env so this makes sure to add the feature branch path like <a href="/feature-branch/logout">
export const formRedirectRoute = (route: string) => {
  const redirectRoute = isFeatureBranch()
    ? // publicPath looks like /feature-branch/ if it exists
      `${process.env.publicPath}${route}`
    : `/${route}`;
  return redirectRoute;
};

// Follow the ?redirect_to query param with the same logic as the login auth_controller.js
export const formRedirectToUrl = (redirectToQueryParam: string) => {
  let redirectToUrl = decodeURIComponent(redirectToQueryParam);
  redirectToUrl = sanitizeUrl(redirectToUrl);
  if (!isAllowListedUrl(redirectToUrl)) {
    redirectToUrl = '/';
  }

  if (isFeatureBranch()) {
    const featureBranchPath = process.env.publicPath || '';

    const isRedirectToAllowListedApp = redirectToUrl.startsWith('https://');
    const hasFeatureBranchPathPrefix = redirectToUrl.startsWith(
      featureBranchPath
    );
    if (!isRedirectToAllowListedApp && !hasFeatureBranchPathPrefix) {
      // featureBranchPath looks like /feature-branch/ and redirectToUrl looks like /redirect/path,
      // so we remove the preceding / from redirectToUrl
      redirectToUrl = `${featureBranchPath}${redirectToUrl.substr(1)}`;
    }
  }

  if (redirectToUrl === '/logout') {
    // Break infinite loop of getting logged out immediately after logging in
    redirectToUrl = '/';
  }

  return redirectToUrl;
};

export const formRouteWithRedirectToQueryParams = (
  route: string,
  redirectToQueryParam: string
) => {
  let newPath = formRedirectRoute(route);

  // We need to honor the redirect_to query param to not break any existing
  // email/download flows after completing/skipping pages like security checkup
  // and validate 2FA, so we pass it along the path
  if (redirectToQueryParam) {
    newPath = `${newPath}?redirect_to=${encodeURIComponent(
      redirectToQueryParam
    )}`;
  }

  return newPath;
};

// Adapted from https://github.com/braintree/sanitize-url/blob/main/src/index.ts
const htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
const protocolAllowList = ['http:', 'https:'];
const unsafeUrlRegex = /^\/(\/|\\)+|^.*?:\/\//i;
// eslint-disable-next-line no-control-regex
const ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
const urlSchemeRegex = /^([^:]+):/gm;
const relativeFirstCharacters = ['.', '/'];

const isRelativeUrlWithoutProtocol = (url: string) => {
  return relativeFirstCharacters.indexOf(url[0]) > -1;
};

const decodeHtmlCharacters = (str: string) => {
  return str.replace(htmlEntitiesRegex, (match, dec) => {
    return String.fromCharCode(dec);
  });
};

export const sanitizeUrl = (url: string) => {
  const sanitizedUrl = decodeHtmlCharacters(url || '')
    .replace(ctrlCharactersRegex, '')
    .trim();

  if (!sanitizedUrl) {
    return '/';
  }

  const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

  if (!urlSchemeParseResults) {
    // Check for potentially unsafe external links
    if (sanitizedUrl.match(unsafeUrlRegex)) return '/';

    return sanitizedUrl;
  }

  const urlScheme = urlSchemeParseResults[0];

  if (protocolAllowList.indexOf(urlScheme) === -1) {
    return '/';
  }

  return sanitizedUrl;
};

const redirectToUrlHostAllowList = [
  'support.sendgrid.com',
  'sendgrid.com',
  'staging.sendgrid.com',
  'app.sendgrid.com',
  'app.testing.sendgrid.com',
  'app.staging.sendgrid.com',
  'mc.sendgrid.com',
  'mc.staging.sendgrid.com',
  'login.sendgrid.com',
  'login.staging.sendgrid.com',
  'login.testing.sendgrid.com',
];

export const isAllowListedUrl = (url: string) => {
  try {
    if (isRelativeUrlWithoutProtocol(url)) {
      return true;
    }

    const parsedUrl = parseURI(url);

    if (redirectToUrlHostAllowList.indexOf(parsedUrl.host) !== -1) {
      return true;
    }

    return false;
  } catch {
    return false;
  }
};
