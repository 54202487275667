// YYYY-MM-DDTHH:mm:ss (cut here, 19th position) .sssZ
export const ISO_DATE_END_OF_SECONDS_INDEX = 19;

export const getDateFirstOfCurrentMonth = (): string => {
  const now = new Date();
  const firstDay = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)
  );
  // Stripping ms so our date is in the same format as OES
  return firstDay.toISOString().slice(0, ISO_DATE_END_OF_SECONDS_INDEX) + 'Z';
};
