import { OfferingsDto, OesAccountDto, OfferingDto } from './dtos';
import {
  AuthedHttp,
  ResponseBodySuccess,
} from '../../../../helpers/AuthedHttp';
import { NewPlanDto } from '../../new_plan/service';
import { ProductId } from '../../../types/productId';
import { RequestError } from 'src/helpers/requests/RequestError';

export async function getAccount() {
  const response = await AuthedHttp.get<OesAccountDto>(
    'accounts/offerings?_show&_pricing&_offerings'
  );
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(
    new RequestError('failed to get OES account', response.status)
  );
}

export async function getFreeMCOffering(org: string): Promise<OfferingDto> {
  const response = await AuthedHttp.get<OfferingsDto>(
    `offerings?_show&org=${org}&mc_tier=free&is_signup_eligible=true`
  );
  if (response.ok) {
    const offerings = await response.json();

    if (offerings.offerings.length === 0) {
      return Promise.reject('no free MC offering found');
    } else if (offerings.offerings.length > 1) {
      return Promise.reject('more than 1 free MC offering found');
    }

    return offerings.offerings[0];
  }
  // TODO: GROWTH - add handling for 400 level errors?
  return Promise.reject(
    new RequestError('failed to get free MC offering from OES', response.status)
  );
}

export async function getOffering(name: string): Promise<OfferingDto> {
  const response = await AuthedHttp.get<OfferingDto>(`offerings/${name}?_show`);
  if (response.ok) {
    return (await response.json()) as OfferingDto;
  }
  // TODO: GROWTH - add handling for 400 level errors?
  return Promise.reject(
    new RequestError('failed to get OES offering', response.status)
  );
}

export async function getOfferings(): Promise<OfferingsDto> {
  const response = await AuthedHttp.get('offerings?_show&_all');
  if (response.ok) {
    return (await response.json()) as OfferingsDto;
  }
  // TODO: GROWTH - add handling for 400 level errors?
  return Promise.reject(
    new RequestError('failed to get OES offerings', response.status)
  );
}

export async function getAssociatedEaseOffering(
  planName: string
): Promise<OfferingDto | undefined> {
  const response = await AuthedHttp.get(
    `offerings?_show=compat&compat_ease_pkg_offerings=${planName}`
  );
  if (response.ok) {
    const responseBody = (await response.json()) as OfferingsDto;
    const offerings = responseBody.offerings || [];
    // Custom EASE is associated with all paid EI plans and isn't self serve.
    // Need to filter it out or it will be returned as the associated EASE
    // addon for all paid EI plans.
    return offerings.filter(
      (offering) => offering.name !== ProductId.SG_X_EASE_30_CUSTOM_V1
    )[0];
  }
  return Promise.reject(
    new RequestError(
      `failed to get an associated EASE offering for ${planName}`,
      response.status
    )
  );
}

export const getNewPlanAccount = async (
  requestDto: NewPlanDto
): Promise<OesAccountDto> => {
  return AuthedHttp.post<OesAccountDto>(
    `accounts/offerings/plan?_prorate&_pricing&_show`,
    requestDto
  ).then((data: ResponseBodySuccess<OesAccountDto>) => {
    return data.json();
  });
};

// todo prorate param when ready
export const getNewPlanAccountDiff = async (
  requestDto: NewPlanDto
): Promise<OesAccountDto> => {
  return AuthedHttp.patch<OesAccountDto>(
    `accounts/offerings/plan?_diff&_show`,
    requestDto
  ).then((data: ResponseBodySuccess<OesAccountDto>) => data.json());
};

export const OES = {
  getAccount,
  getOfferings,
  getFreeMCOffering,
  getNewPlanAccount,
  getNewPlanAccountDiff,
  getOffering,
  getAssociatedEaseOffering,
};
