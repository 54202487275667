import { createSelector } from '@reduxjs/toolkit';
import { api } from '../apiSlice';
import { RequestTypes } from '../../../../helpers/AuthedHttp';
import { CamelCasedProperties } from 'type-fest';
import {
  convertAllKeysToCamelCase,
  dataInjectionKey,
  RtkQueryDataInjection,
} from '../utils';
import { Endpoints } from '../Endpoints';

/** Key is raw catalog_org from CAM, value is human readable version */
const catalogOrgToResellerName = {
  sg: 'SendGrid',
  tw: 'Twilio',
  google: 'Google',
  azure: 'Azure',
  heroku: 'Heroku',
  ibm: 'IBM',
  kke: 'KKE',
  aws: 'AWS',
};

export type CatalogOrg = keyof typeof catalogOrgToResellerName;

export interface CamAccountDto {
  account_id: string;
  catalog_org?: CatalogOrg;
  billing_org?: string;
  twilio_sid?: string;
  new_upgrade_enabled?: boolean;
}

export type CamAccount = CamelCasedProperties<CamAccountDto>;

export const camAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    [Endpoints.fetchCamAccount]: builder.query<
      CamAccount,
      { [dataInjectionKey]?: RtkQueryDataInjection } | void
    >({
      query: ({ dataInjection } = {}) => ({
        url: 'accounts',
        method: RequestTypes.Get,
        dataInjection,
      }),
      transformResponse: (data: CamAccountDto) =>
        convertAllKeysToCamelCase<CamAccountDto>(data),
    }),
  }),
});

const selectCamAccountResult = camAccountApi.endpoints[
  Endpoints.fetchCamAccount
].select();

export const selectIsSpecificCatalogOrg = createSelector(
  selectCamAccountResult,
  (state: any, catalogOrg: CatalogOrg) => catalogOrg,
  (camAccountResult, catalogOrg) =>
    camAccountResult?.data?.catalogOrg === catalogOrg
);

/**
 * Returns the human readable version of the catalog_org from CAM.
 * E.g. if cam returned {catalog_org: 'heroku'}, this will return a capitalized 'Heroku'.
 */
export const selectHumanReadableCatalogOrg = createSelector(
  selectCamAccountResult,
  (camAccountResult) =>
    camAccountResult?.data?.catalogOrg === undefined
      ? ''
      : catalogOrgToResellerName[camAccountResult.data.catalogOrg] ?? ''
);

export const { useFetchCamAccountQuery } = camAccountApi;
