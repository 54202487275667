import React from 'react';
import { Alert } from '@twilio-paste/alert';
import { Text } from '@twilio-paste/core/text';
import { formatTrialEndDate } from '../utils';
import { content } from '../content';

export interface Props {
  eiEndOfTrialDate: string | null;
  mcEndOfTrialDate: string | null;
}

export const TrialEnding: React.VFC<Props> = ({
  eiEndOfTrialDate,
  mcEndOfTrialDate,
}) => {
  const formattedEiEndOfTrialDate =
    eiEndOfTrialDate && formatTrialEndDate(eiEndOfTrialDate);
  const formattedMcEndOfTrialDate =
    mcEndOfTrialDate && formatTrialEndDate(mcEndOfTrialDate);

  let description = null;
  if (formattedEiEndOfTrialDate && formattedMcEndOfTrialDate) {
    description = content.eiAndMcTrialEnding(
      formattedEiEndOfTrialDate,
      formattedMcEndOfTrialDate
    );
  } else if (formattedEiEndOfTrialDate) {
    description = content.eiTrialEnding(formattedEiEndOfTrialDate);
  } else if (formattedMcEndOfTrialDate) {
    description = content.mcTrialEnding(formattedMcEndOfTrialDate);
  }

  return (
    <Alert variant="neutral">
      <Text as="p">{description}</Text>
    </Alert>
  );
};
