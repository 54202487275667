import ReactDOM from 'react-dom';
import React from 'react';
import { Theme } from '@twilio-paste/theme';
import { Provider } from 'react-redux';
import { legacyBillingStoreContext } from 'src/state/store';
import { AnyAction, Store } from 'redux';
import TrialNotifications from './TrialNotifications';

interface Props {
  querySelector: string;
  store: Store<any, AnyAction>;
  sendgridAppStore: Store<any, AnyAction>;
}

export function TrialNotificationsContainer({
  querySelector,
  store,
  sendgridAppStore,
}: Props) {
  const reactNode = document.querySelector(querySelector);
  if (reactNode) {
    return ReactDOM.render(
      <React.StrictMode>
        <Provider store={store} context={legacyBillingStoreContext}>
          <Provider store={sendgridAppStore}>
            <Theme.Provider theme="twilio">
              <TrialNotifications />
            </Theme.Provider>
          </Provider>
        </Provider>
      </React.StrictMode>,
      reactNode
    );
  }
}
