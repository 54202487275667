import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';

import { ActionTypes } from './actionTypes';
import {
  AccountProfile,
  AccountInfo,
  Username,
  CompanyWebsite,
  CompanyName,
  FriendlyName,
} from '../types/user';
import { accountProfileAdapter } from './adapters';

import {
  getAccountInfoRefinedErrorMessage,
  getEmailRefinedErrorMessage,
  getUsernameRefinedErrorMessage,
  getCompanyNameRefinedErrorMessage,
  getCompanyWebsiteRefinedErrorMessage,
  getCompanyAddressRefinedErrorMessage,
} from './errors';
import {
  AccountProfileDto,
  EmailDto,
  PasswordDto,
  UserProfileDto,
  UsernameDto,
} from '../dtos';

// This is a placeholder for all the account_profile data we get from Tiara
// until we know how we want to organize it.
const initialState = {
  emailConfirmation: {
    isSent: false,
    isModalOpen: false,
  },
  errors: {
    accountInfoErrorMessage: '',
    emailErrorMessage: '',
    usernameErrorMessage: '',
    friendlyNameErrorMessage: '',
    passwordErrorMessage: '',
    companyNameErrorMessage: '',
    companyWebsiteErrorMessage: '',
    addressErrorMessage: '',
  },
} as AccountProfile;

interface TiaraAccountProfile {
  accountProfile: AccountProfileDto;
  profile: UserProfileDto;
  email: EmailDto;
  username: UsernameDto;
}

interface UserAction extends Action {
  payload:
    | MakoStateType['accountProfile']
    | MakoStateType['email']
    | TiaraAccountProfile
    | AccountProfileDto
    | AccountInfo;
}

export const accountProfile = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case ActionTypes.CreateUserProfile: {
      // Current user (teammate or parent admin) specific email/username
      const emailDto = (action.payload as TiaraAccountProfile).email;
      const { email: currentUserEmail } = emailDto;
      const usernameDto = (action.payload as TiaraAccountProfile).username;
      const { username: currentUsername } = usernameDto;
      // This contains the parent admin profile information i.e. admin's username, email, address, person
      const accountProfileDto = (action.payload as TiaraAccountProfile)
        .accountProfile;
      const { username: adminUsername, email: adminEmail } = accountProfileDto;
      // Current user (teammate or parent admin) specific profile information
      const userProfileDto = (action.payload as TiaraAccountProfile).profile;
      const { userid: adminUserId } = userProfileDto;

      // We need to keep track of the admin account to determine whether or not
      // the user is a teammate and thus hide the company section and display
      // the admin section
      const adminAccount = {
        username: adminUsername,
        email: adminEmail,
        userId: adminUserId,
      };
      // We need to merge them to guarantee that we get the teammate specific data
      // if the user is not a parent admin account. Parent admin accounts will have the same
      // Person and Address values returned from both /account/profile and /user/profile
      // ^^ UPDATE: Unfortunately this is no longer true as the account profile info comes
      //            from the new v2 endpoint and the old /user/profile endpoint does not
      //            return the same thing for unified parent accounts
      const isParentAccount = adminUserId === userProfileDto.userid;

      const createdAccountProfileDto = {
        ...accountProfileDto,
        ...(isParentAccount ? {} : userProfileDto),
        username: currentUsername,
      };

      return {
        ...accountProfileAdapter(createdAccountProfileDto, currentUserEmail),
        adminAccount,
        emailConfirmation: {
          isSent: false,
          isModalOpen: false,
        },
        errors: {
          accountInfoErrorMessage: '',
          emailErrorMessage: '',
          usernameErrorMessage: '',
          friendlyNameErrorMessage: '',
          passwordErrorMessage: '',
          companyNameErrorMessage: '',
          companyWebsiteErrorMessage: '',
          addressErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateUserProfileSuccess: {
      const { errors: currentErrors } = state;
      const updatedAccountProfile = accountProfileAdapter(
        action.payload as AccountProfileDto,
        state.email
      );
      return {
        ...state,
        ...updatedAccountProfile,
        errors: {
          ...currentErrors,
          accountInfoErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateUserProfileFailure: {
      const { errors: currentErrors } = state;
      const { errors: accountInfoErrors } = action.payload as AccountInfo;
      const accountInfoErrorMessage =
        (accountInfoErrors && accountInfoErrors[0].message) || '';
      const refinedAccountInfoErrorMessage = getAccountInfoRefinedErrorMessage(
        accountInfoErrorMessage
      );

      return {
        ...state,
        errors: {
          ...currentErrors,
          accountInfoErrorMessage: refinedAccountInfoErrorMessage,
        },
      };
    }

    case ActionTypes.ClearAccountInfoServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          accountInfoErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateEmailRequest: {
      const { emailConfirmation } = state;
      return {
        ...state,
        emailConfirmation: {
          ...emailConfirmation,
          isSent: false,
        },
      };
    }

    case ActionTypes.UpdateEmailSuccess: {
      const { errors: currentErrors, emailConfirmation } = state;
      const { email } = action.payload as EmailDto;
      return {
        ...state,
        email,
        emailConfirmation: {
          ...emailConfirmation,
          isSent: true,
        },
        errors: {
          ...currentErrors,
          emailErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateEmailFailure: {
      const { errors: currentErrors } = state;
      const { errors: emailErrors } = action.payload as AccountInfo;
      const emailErrorMessage = (emailErrors && emailErrors[0].message) || '';
      const refinedEmailErrorMessage = getEmailRefinedErrorMessage(
        emailErrorMessage
      );

      return {
        ...state,
        emailConfirmation: {
          isSent: false,
          isModalOpen: false,
        },
        errors: {
          ...currentErrors,
          emailErrorMessage: refinedEmailErrorMessage,
        },
      };
    }

    case ActionTypes.OpenEmailConfirmationModal: {
      return {
        ...state,
        emailConfirmation: {
          isModalOpen: true,
          isSent: false,
        },
      };
    }

    case ActionTypes.CloseEmailConfirmationModal: {
      return {
        ...state,
        emailConfirmation: {
          isModalOpen: false,
          isSent: false,
        },
      };
    }

    case ActionTypes.AllowResendConfirmation: {
      const { emailConfirmation } = state;
      return {
        ...state,
        emailConfirmation: {
          ...emailConfirmation,
          isSent: false,
        },
      };
    }

    case ActionTypes.ClearEmailServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          emailErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateUsernameSuccess: {
      const { username } = action.payload as Username;
      const { errors: currentErrors } = state;

      return {
        ...state,
        errors: {
          ...currentErrors,
          usernameErrorMessage: '',
        },
        username,
      };
    }

    case ActionTypes.UpdateUsernameFailure: {
      const { errors: currentErrors } = state;
      const usernameErrors = action.payload as Username;
      const serverErrorMessage =
        (usernameErrors.errors && usernameErrors.errors[0].message) || '';
      const refinedErrorMessage = getUsernameRefinedErrorMessage(
        serverErrorMessage
      );

      return {
        ...state,
        errors: {
          ...currentErrors,
          usernameErrorMessage: refinedErrorMessage,
        },
      };
    }

    case ActionTypes.ClearUsernameServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          usernameErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateFriendlyNameSuccess: {
      const { errors: currentErrors } = state;
      const { friendlyName } = action.payload as FriendlyName;

      return {
        ...state,
        friendlyName,
        errors: {
          ...currentErrors,
          friendlyNameErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateFriendlyNameFailure: {
      const { errors: currentErrors } = state;
      const friendlyNameErrors = action.payload as FriendlyName;
      const serverErrorMessage =
        (friendlyNameErrors.errors && friendlyNameErrors.errors[0].message) ||
        '';

      return {
        ...state,
        errors: {
          ...currentErrors,
          friendlyNameErrorMessage: serverErrorMessage,
        },
      };
    }

    case ActionTypes.ClearFriendlyNameServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          friendlyNameErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdatePasswordSuccess: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          passwordErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdatePasswordFailure: {
      const { errors: currentErrors } = state;
      const { errors } = action.payload as Pick<PasswordDto, 'errors'>;
      const serverErrorMessage = (errors && errors[0]) || undefined;
      let errorMessage = '';

      if (serverErrorMessage && serverErrorMessage.field === 'new_password') {
        errorMessage =
          'Please update your password to meet the security requirements.';
      } else {
        errorMessage = 'An error has occurred. Please try again';
      }

      return {
        ...state,
        errors: {
          ...currentErrors,
          passwordErrorMessage: errorMessage,
          inlinePasswordErrorMessage: serverErrorMessage,
        },
      };
    }

    case ActionTypes.ClearPasswordServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          passwordErrorMessage: '',
          inlinePasswordErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateCompanyNameSuccess: {
      const { errors: currentErrors } = state;
      const { company } = action.payload as CompanyName;

      return {
        ...state,
        company,
        errors: {
          ...currentErrors,
          companyNameErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateCompanyNameFailure: {
      const { errors: currentErrors } = state;
      const companyNameErrors = action.payload as CompanyName;
      const serverCompanyNameErrorMessage =
        (companyNameErrors.errors && companyNameErrors.errors[0].message) || '';
      const refinedCompanyNameErrorMessage = getCompanyNameRefinedErrorMessage(
        serverCompanyNameErrorMessage
      );

      return {
        ...state,
        errors: {
          ...currentErrors,
          companyNameErrorMessage: refinedCompanyNameErrorMessage,
        },
      };
    }

    case ActionTypes.ClearCompanyNameServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          companyNameErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateCompanyWebsiteSuccess: {
      const { website } = action.payload as CompanyWebsite;
      const { errors: currentErrors } = state;

      return {
        ...state,
        errors: {
          ...currentErrors,
          companyWebsiteErrorMessage: '',
        },
        website,
      };
    }

    case ActionTypes.UpdateCompanyWebsiteFailure: {
      const websiteErrors = action.payload as CompanyWebsite;
      const serverWebsiteErrorMessage =
        (websiteErrors.errors && websiteErrors.errors[0].message) || '';
      const refinedWebsiteErrorMessage = getCompanyWebsiteRefinedErrorMessage(
        serverWebsiteErrorMessage
      );
      const { errors: currentErrors } = state;

      return {
        ...state,
        errors: {
          ...currentErrors,
          companyWebsiteErrorMessage: refinedWebsiteErrorMessage,
        },
      };
    }

    case ActionTypes.UpdateAddressSuccess: {
      const updatedAccountAddressProfile = {
        ...(action.payload as AccountProfile),
        email: state.email,
      };

      return {
        ...state,
        ...updatedAccountAddressProfile,
        errors: {
          ...state.errors,
          addressErrorMessage: '',
        },
      };
    }

    case ActionTypes.UpdateAddressFailure: {
      const { errors: currentErrors } = state;
      const addressErrors = action.payload as AccountProfileDto;
      const serverError = (addressErrors.errors && addressErrors.errors[0]) || {
        field: '',
        message: '',
      };
      const refinedErrorMessage = getCompanyAddressRefinedErrorMessage(
        serverError
      );

      return {
        ...state,
        errors: {
          ...currentErrors,
          addressErrorMessage: refinedErrorMessage,
        },
      };
    }

    case ActionTypes.ClearAddressServerErrors: {
      const { errors: currentErrors } = state;
      return {
        ...state,
        errors: {
          ...currentErrors,
          addressErrorMessage: '',
        },
      };
    }

    case ActionTypes.ClearCompanyWebsiteServerErrors: {
      const { errors: currentErrors } = state;

      return {
        ...state,
        errors: {
          ...currentErrors,
          companyWebsiteErrorMessage: '',
        },
      };
    }

    default:
      return state;
  }
};
