import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { PaymentMethodDto } from '../dtos';
import { ActionTypes } from './actionTypes';
import { paymentMethodAdapter } from './adapters';
import { PaymentMethod } from '../types/paymentMethod';
import { ResponseError } from '../types/responseError';

const initialState = {} as PaymentMethod;

export interface PaymentMethodActions extends Action {
  payload: MakoStateType['paymentMethod'] | PaymentMethodDto | ResponseError[];
}

export const paymentMethod = (
  state = initialState,
  action: PaymentMethodActions
) => {
  switch (action.type) {
    case ActionTypes.GetPaymentMethodSuccess:
    case ActionTypes.UpdateDefaultPaymentMethodSuccess:
      return {
        ...paymentMethodAdapter(action.payload as PaymentMethodDto),
      };
    case ActionTypes.GetPaymentMethodFailure:
      return {
        ...action.payload,
      };
    case ActionTypes.UpdateDefaultPaymentMethodFailure:
      return {
        ...state,
        ...{ errors: action.payload as ResponseError[] },
      };
    case ActionTypes.ClearPaymentMethodError:
      return {
        ...state,
        errors: undefined,
      };
    case ActionTypes.ReactivateUserSuccess:
      return state;
    case ActionTypes.ReactivateUserFailure:
      return state;
    default:
      return state;
  }
};
