import { EmailApiSubscription } from 'src/state/types/emailApiSubscription';
import { MarketingCampaignsSubscription } from 'src/state/types/marketingCampaignsSubscription';

export const EI_TRIAL_PREFIX = 'sg.ei.trial';
export const MC_TRIAL_PREFIX = 'sg.mc.trial';

export const EI_END_OF_TRIAL_PREFIX = 'sg.ei.end-of-trial';
export const MC_END_OF_TRIAL_PREFIX = 'sg.mc.end-of-trial';

export const isEiEndOfTrial = (subscription: EmailApiSubscription) => {
  return subscription.planId.startsWith(EI_END_OF_TRIAL_PREFIX);
};

export const isEiOnTrial = (subscription: EmailApiSubscription) => {
  return subscription.planId.startsWith(EI_TRIAL_PREFIX);
};

export const isMcEndOfTrial = (
  subscription: MarketingCampaignsSubscription
) => {
  return subscription.planId.startsWith(MC_END_OF_TRIAL_PREFIX);
};

export const isMcOnTrial = (subscription: MarketingCampaignsSubscription) => {
  return subscription.planId.startsWith(MC_TRIAL_PREFIX);
};
