import React from 'react';
import { Badge } from '@twilio-paste/badge';
import { Anchor } from '@twilio-paste/anchor';
import { Text } from '@twilio-paste/core/text';
import words from '../en';

interface Props {
  showEiUpgrade: boolean;
  showMcUpgrade: boolean;
}

const prefix = process.env.publicPath ?? '/';
export const UPGRADE_EI_AND_MC_PATH = `${prefix}account/products`;
export const UPGRADE_EI_PATH = `${prefix}account/billing/choose_plan`;
export const UPGRADE_MC_PATH = `${prefix}account/billing/choose_plan_marketing_campaigns`;

export const TrialUpgradeBadge: React.VFC<Props> = ({
  showEiUpgrade,
  showMcUpgrade,
}) => {
  let text, link;
  if (showEiUpgrade && showMcUpgrade) {
    text = `${words.trialsBadge.eiUpgradeText} & ${words.trialsBadge.mcUpgradeText}`;
    link = UPGRADE_EI_AND_MC_PATH;
  } else if (showEiUpgrade) {
    text = `${words.trialsBadge.eiUpgradeText}`;
    link = UPGRADE_EI_PATH;
  } else if (showMcUpgrade) {
    text = `${words.trialsBadge.mcUpgradeText}`;
    link = UPGRADE_MC_PATH;
  } else {
    text = '';
    link = '#';
  }

  return (
    <Badge as="span" variant="success">
      <Text
        as="p"
        fontWeight={'fontWeightNormal'}
        fontSize={'fontSize20'}
        lineHeight={'lineHeight10'}
      >
        {words.trialsBadge.label}
        {': '}
      </Text>
      {text}
      <Anchor href={link}>{words.trialsBadge.upgradeLink}</Anchor>
    </Badge>
  );
};
