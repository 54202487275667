import React from 'react';
import { EmailApiSubscription } from 'src/state/types/emailApiSubscription';
import { MarketingCampaignsSubscription } from 'src/state/types/marketingCampaignsSubscription';
import { Box } from '@twilio-paste/core/box';
import { withTrialBannerConnector } from './Connector';
import { TrialEnding } from './Banner/TrialEnding';
import { TrialEnded } from './Banner/TrialEnded';
import { getEndOfTrialDate } from './utils';
import { isEiEndOfTrial, isMcEndOfTrial } from 'src/helpers/offerings/trials';

export interface Props {
  emailApiSubscription?: EmailApiSubscription;
  marketingCampaignsSubscription?: MarketingCampaignsSubscription;
  children?: React.ReactNode;
}

export const TrialNotifications: React.VFC<Props> = ({
  emailApiSubscription,
  marketingCampaignsSubscription,
}) => {
  if (!emailApiSubscription || !marketingCampaignsSubscription) {
    return null;
  }

  const eiEndOfTrial = isEiEndOfTrial(emailApiSubscription);
  const mcEndOfTrial = isMcEndOfTrial(marketingCampaignsSubscription);
  const eiEndOfTrialDate = getEndOfTrialDate(emailApiSubscription);
  const mcEndOfTrialDate = getEndOfTrialDate(marketingCampaignsSubscription);

  return (
    <Box>
      {(eiEndOfTrial || mcEndOfTrial) && (
        <TrialEnded
          isOnEiEndOfTrial={eiEndOfTrial}
          isOnMcEndOfTrial={mcEndOfTrial}
        />
      )}
      {(eiEndOfTrialDate || mcEndOfTrialDate) && (
        <TrialEnding
          eiEndOfTrialDate={eiEndOfTrialDate}
          mcEndOfTrialDate={mcEndOfTrialDate}
        />
      )}
    </Box>
  );
};

export default withTrialBannerConnector(TrialNotifications);
