import React from 'react';
import { Callout, CalloutHeading, CalloutText } from '@twilio-paste/core';
import { content } from '../content';

export interface Props {
  isOnEiEndOfTrial: boolean;
  isOnMcEndOfTrial: boolean;
}

export const TrialEnded: React.VFC<Props> = ({
  isOnEiEndOfTrial,
  isOnMcEndOfTrial,
}) => {
  let text = { title: '', description: '' };
  if (isOnEiEndOfTrial && isOnMcEndOfTrial) {
    text = content.eiAndMcTrialEnded;
  } else if (isOnEiEndOfTrial) {
    text = content.eiTrialEnded;
  } else if (isOnMcEndOfTrial) {
    text = content.mcTrialEnded;
  }

  const { title, description } = text;

  return (
    <Callout variant="warning">
      <CalloutHeading>{title}</CalloutHeading>
      <CalloutText>{description}</CalloutText>
    </Callout>
  );
};
