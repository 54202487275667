export const content = {
  eiTrialEnded: {
    title: 'Email API trial ended',
    description:
      'Your trial period has ended. You will not be able to send any emails through Email API. Upgrade your plan to regain access to sending emails.',
  },
  mcTrialEnded: {
    title: 'Marketing Campaigns trial ended',
    description:
      'Your trial period has ended. You will not be able to send any emails through Marketing Campaigns. Upgrade your plan to regain access to sending emails.',
  },
  eiAndMcTrialEnded: {
    title: 'Email API and Marketing Campaigns trial ended',
    description:
      'Your trial period has ended. You will not be able to send any emails through Email API and Marketing Campaigns. Upgrade your plan to regain access to sending emails.',
  },
  eiTrialEnding: (endDate: string) =>
    `Your Email API free trial ends on ${endDate}. Upgrade now to get the most out of your email program.`,
  mcTrialEnding: (endDate: string) =>
    `Your Marketing Campaigns free trial ends on ${endDate}. Upgrade now to get the most out of your email program.`,
  eiAndMcTrialEnding: (eiTrialEnding: string, mcTrialEnding: string) => {
    const differentDates = `Your Email API free trial ends on ${eiTrialEnding} and your Marketing Campaigns free trial ends on ${mcTrialEnding}`;
    const sameDates = `Your Email API and Marketing Campaigns free trials end on ${eiTrialEnding}`;
    return `${
      eiTrialEnding === mcTrialEnding ? sameDates : differentDates
    }. Upgrade now to get the most out of your email program.`;
  },
};
