const words = {
  twilioHomeButton: {
    twilioHomeLabel: 'Twilio Home',
    logoTitle: 'Go to Twilio Console homepage',
  },
  accountNameDropdown: {
    viewSubusersItemLabel: 'View subusers',
    goBackToMainAccountItemLabel: 'Go back to main account',
    viewAllAccountsItemLabel: 'View all accounts',
  },
  accountTypeBadge: {
    subuserLabel: 'Subuser',
    trialLabel: 'Trial',
    upgradeLabel: 'Upgrade',
  },
  trialsBadge: {
    label: 'Trial',
    eiUpgradeText: 'API',
    mcUpgradeText: 'MC',
    upgradeLink: 'Upgrade',
  },
  logoAlt: 'Twilio SendGrid Logo',
  twilioSGBranding: 'Twilio SendGrid',
};

export default words;
