import { RequestError } from 'src/helpers/requests/RequestError';
import { AuthedHttp } from '../../../../helpers/AuthedHttp';
import {
  FeatureToggleDto,
  UsageStatsDto,
  LegacyMarketingCampaignsContactsCountDto,
  MarketingCampaignsAutomationDto,
  MarketingCampaignsContactsCountDto,
  MarketingCampaignsSignupFormDto,
  SubuserDto,
  ApiKeysDto,
  ApiKeyDto,
} from './dtos';

enum Routes {
  EAPIUsages = 'usages',
  LegacyMCBillableCount = 'contactdb/recipients/billable_count',
  MCAutomations = 'mc/automations',
  MCContactCount = 'mc/contacts/count',
  MCForms = 'mc/forms',
  ApiKeys = 'api_keys',
  FeatureToggle = 'feature_toggles',
  Subusers = 'subusers',
}

const FORBIDDEN = 403;

export const getMarketingCampaignsAutomations = async (): Promise<
  MarketingCampaignsAutomationDto[]
> => {
  const response = await AuthedHttp.get<MarketingCampaignsAutomationDto[]>(
    Routes.MCAutomations
  );
  if (response.ok) {
    return response.json();
  }

  if (response.status === FORBIDDEN) {
    const teammateWithReadOnlyAccess = {} as MarketingCampaignsAutomationDto;
    return Promise.resolve([teammateWithReadOnlyAccess]);
  }
  return Promise.reject(
    new RequestError(
      'failed to get marketing campaigns automations',
      response.status
    )
  );
};

export const getLegacyMarketingCampaignsContactsCount = async (): Promise<
  LegacyMarketingCampaignsContactsCountDto
> => {
  const response = await AuthedHttp.get<
    LegacyMarketingCampaignsContactsCountDto
  >(Routes.LegacyMCBillableCount);
  if (response.ok) {
    return response.json();
  }

  if (response.status === FORBIDDEN) {
    const teammateWithReadAccess = {
      recipient_count: 0,
    } as LegacyMarketingCampaignsContactsCountDto;
    return Promise.resolve(teammateWithReadAccess);
  }

  return Promise.reject(
    new RequestError(
      'failed to get marketing campaigns legacy contacts',
      response.status
    )
  );
};

export const getMarketingCampaignsContactsCount = async (): Promise<
  MarketingCampaignsContactsCountDto
> => {
  const response = await AuthedHttp.get<MarketingCampaignsContactsCountDto>(
    Routes.MCContactCount
  );
  if (response.ok) {
    return response.json();
  }

  if (response.status === FORBIDDEN) {
    const teammateWithReadAccess = {
      contact_count: 0,
      billable_count: 0,
      billable_breakdown: undefined,
    } as MarketingCampaignsContactsCountDto;
    return Promise.resolve(teammateWithReadAccess);
  }

  return Promise.reject(
    new RequestError(
      'failed to get marketing campaigns contacts',
      response.status
    )
  );
};

export const getSubusers = async (): Promise<SubuserDto[]> => {
  const response = await AuthedHttp.get<SubuserDto[]>(Routes.Subusers);
  if (response.ok) {
    return response.json();
  }

  if (response.status === FORBIDDEN) {
    const teammateWithReadAccess = {} as SubuserDto;
    return Promise.resolve([teammateWithReadAccess]);
  }

  return Promise.reject(
    new RequestError('failed to get subusers', response.status)
  );
};

export const getUsageStats = async (): Promise<UsageStatsDto> => {
  const response = await AuthedHttp.get<UsageStatsDto>(Routes.EAPIUsages);
  if (response.ok) {
    return response.json();
  }

  return Promise.reject(
    new RequestError('failed to get email api usage', response.status)
  );
};

export const getMarketingCampaignsSignupForms = async (): Promise<
  MarketingCampaignsSignupFormDto[]
> => {
  const response = await AuthedHttp.get<MarketingCampaignsSignupFormDto[]>(
    Routes.MCForms
  );

  if (response.ok) {
    return response.json();
  }

  if (response.status === FORBIDDEN) {
    const teammateWithReadAccess = {} as MarketingCampaignsSignupFormDto;
    return Promise.resolve([teammateWithReadAccess]);
  }

  return Promise.reject(
    new RequestError(
      'failed to get marketing campaigns sign up forms',
      response.status
    )
  );
};

export const getApiKeys = async (): Promise<ApiKeysDto> => {
  const response = await AuthedHttp.get<ApiKeysDto>(Routes.ApiKeys);
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(
    new RequestError('failed to get api keys', response.status)
  );
};

export const getFeatureToggles = async (): Promise<FeatureToggleDto[]> => {
  const response = await AuthedHttp.get<FeatureToggleDto[]>(
    Routes.FeatureToggle
  );
  if (response.ok) {
    return response.json();
  }

  return Promise.reject(
    new RequestError('failed to get feature toggles', response.status)
  );
};

export const getApiKey = async (apiKeyId: string): Promise<ApiKeyDto> => {
  const response = await AuthedHttp.get<ApiKeyDto>(
    `${Routes.ApiKeys}/${apiKeyId}`
  );
  if (response.ok) {
    return response.json();
  }

  return Promise.reject(
    new RequestError('failed to get api key', response.status)
  );
};

export const MakoApi = {
  getUsageStats,
  getMarketingCampaignsAutomations,
  getLegacyMarketingCampaignsContactsCount,
  getMarketingCampaignsContactsCount,
  getMarketingCampaignsSignupForms,
  getSubusers,
  getApiKeys,
  getApiKey,
};
